













import Vue from 'vue';

import Header from './components/Header.vue';
import SideBar from './components/SideBar.vue';
import Main from './components/Main.vue';
import update from './mixins/update';

export default Vue.extend({
  name: 'App',
  components: {
    Header,
    SideBar,
    Main,
  },
  mixins: [update],
});
