


















import { Fields } from 'src/types/interface';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, NodeData } from '@contentful/rich-text-types';
import { SectionsIds } from 'src/types/interface';
import Vue from 'vue';

// import SvgIcon from '../components/SvgIcon.vue';
// import CurrentChallenges from '../components/icons/CurrentChallenges.vue';
// import Blog from '../components/icons/Blog.vue';
// import Hobbies from '../components/icons/Hobbies.vue';
// import { createClient } from '@/plugins/contentful.js';
// import * as contentful from 'contentful';

// const client = createClient();
import { IHomeFields } from '../../@types/generated/contentful';
const cachedSections = [
  {
    name: 'Few words about me',
    contents:
      "Passionate by development for many years especially around UI/UX and Web performances topics, I love to offer users an optimal experience. A dedicated and detail-minded person applying analytical, design, technical skills to supervise the entire software development lifecycle. Long-term focus and innovative team spirit towards satisfying customers' needs in a strategic initiative at a growth company. Hands-on-oriented mindset with extensive experience in software engineering and in leading development teams.",
  },
  {
    name: 'Blog',
    contents:
      'My latest posts in the blog section are: Service worker caching strategies; PWA in 2021; Wrap any rest API with GraphQL from the frontend; Save money and time with a headless CMS',
  },
  {
    name: 'Hobbies',
    contents:
      'Tech watch by reading my twitter and dev.to feeds as soon as I a have a small amount of time in front of me. Trying out tons of sample projects of what "my feeds" inspired me to get my hands on. Playing video games, Drones, taking cool and nice pictures of a lot of things around me.',
  },
];

export default Vue.extend({
  name: 'Home' as string,
  components: {
    // SvgIcon,
    // CurrentChallenges,
    // Blog,
    // Hobbies,
  },
  data() {
    return {
      data: {
        sections: cachedSections as any,
      } as any,
    };
  },
  async created() {
    this.getHomeContent();
  },
  methods: {
    getSectionIds(sectionsArray: any[]) {
      const sectionsIds: any[] = [];
      for (let i = 0; i < sectionsArray.length; i++) {
        const sectionId = sectionsArray[i].sys.id;
        sectionsIds.push(sectionId);
      }
      // console.log(sectionsIds);
      return sectionsIds;
    },
    async getSection(sectionId: string) {
      await this.$contentful.getEntry<IHomeFields>(sectionId).then(data => {
        const options: NodeData = {
          renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: ({
              data: {
                target: { fields },
              },
            }: Fields) =>
              `<img src="${fields.file.url}"
                   height="100%"
                   width="100%"
                   alt="${fields.description}"/>`,
          },
        };
        data.fields.contents = documentToHtmlString(
          data.fields.contents,
          options,
        );
        if (data.fields.name === 'Few words about me') {
          this.data.sections.splice(0, 1, data.fields);
        } else if (data.fields.name === 'Blog') {
          this.data.sections.splice(1, 1, data.fields);
        } else if (data.fields.name === 'Hobbies') {
          this.data.sections.splice(2, 1, data.fields);
        }
      });
      // console.log(this.data.sections);
    },
    async getHomeContent() {
      await this.$contentful
        .getEntry<SectionsIds>('6mKEsHdAD6XuHi6eL5PZSA')
        .then(data => {
          return this.getSectionIds(data.fields.sections);
        })
        .then(sectionIds => {
          for (let i = 0; i < sectionIds.length; i++) {
            this.getSection(sectionIds[i]);
          }
        })
        .catch(err => {
          console.log('error', err);
        });
    },
  },
});
