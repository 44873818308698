
























































import Vue from 'vue';

import SvgIcon from '../components/SvgIcon.vue';
import Twitter from '../components/icons/Twitter.vue';
import Instagram from '../components/icons/Instagram.vue';
import LinkedIn from '../components/icons/LinkedIn.vue';

export default Vue.extend({
  name: 'SideBar',
  components: {
    SvgIcon,
    Twitter,
    Instagram,
    LinkedIn,
  },
  data() {
    return {
      skills: [], // <-- cast it
    };
  },
  created() {
    // this.getSkills();
  },
  methods: {
    goToSocialLink(link: string) {
      window.open(link, '_blank');
    },
  },
});
