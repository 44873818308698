import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';

import contentfulPlugin from './plugins/contentful';

const host = process.env.VUE_APP_CONTENTFUL_HOST;
const accessToken =
  process.env.NODE_ENV === 'development'
    ? process.env.VUE_APP_CONTENTFUL_PREVIEW_API_ACCESS_TOKEN
    : process.env.VUE_APP_CONTENTFUL_DELIVRY_API_ACCESS_TOKEN;

Vue.use(contentfulPlugin, {
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
  accessToken: accessToken,
  host: host,
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
