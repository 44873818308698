import {
  createClient,
  ContentfulClientApi,
  CreateClientParams,
} from 'contentful';

declare module 'vue/types/vue' {
  interface Vue {
    $contentful: ContentfulClientApi;
  }
}

let client = null;
const contentfulPlugin = {
  install(
    Vue: { prototype: { $contentful: ContentfulClientApi } },
    options: {
      space: string;
      accessToken: string;
      environment: string;
      host: string;
    },
  ) {
    client = createClient({
      space: options.space,
      accessToken: options.accessToken,
      environment: options.environment || 'master',
      host: options.host,
    } as CreateClientParams);

    Vue.prototype.$contentful = client;
  },
};

export default contentfulPlugin;
